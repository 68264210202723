import config from '@configs/index'
import { IHeader, IPage, IPageStaticLanding } from '@features/cms/cms.type'
import { ICommerceHclPage, IFooter, IMegaMenu, IQueryParams, ISitemap } from '@typesApp/cms'
import { BaseArgs } from '@typesApp/common'
import { logger } from '../../../logging'
import ApiService from '../api'
import { Helper, helper } from '../helpers'

const cmsURL =
  config.cmsApiUrlInternal && config.cmsApiUrlInternal.trim().endsWith('/')
    ? config.cmsApiUrlInternal
    : `${config.cmsApiUrlInternal}/`
const apiService = new ApiService(cmsURL)

export interface CmsPageArgs extends BaseArgs {
  locale?: string
  excludePlacements?: string[]
  [key: `section.${string}.limit`]: string | undefined
  country: string
  noEnrich: string | undefined
  previewDate?: string
  filterRulesLocaleOverride?: string
  offset: string | undefined
}

export interface SitemapPageArgs {
  locale: string
  country: string
  hostname: string
}

export interface CmsStaticLandingArgs extends CmsPageArgs {
  pageId?: string
}

class CmsApiService {
  private api: ApiService
  private helper: Helper
  constructor(api: ApiService, helper: Helper) {
    this.api = api
    this.helper = helper
  }

  public getHomePage = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country } = args
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      noEnrich: args?.noEnrich,
      ...(args?.['section.main.limit'] ? { 'section.main.limit': args['section.main.limit'] } : {}),
      previewDate: args?.previewDate,
      filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
    })

    const url = `cms/live/home/content/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get home page data from cms ${url}`)
    return this.api.get<IPage>(url)
  }

  public getHeader = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country } = args
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      excludePlacements: args?.excludePlacements,
      previewDate: args?.previewDate,
      filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
    })

    const url = `cms/live/page/header/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get header data from cms ${url}`)
    return this.api.get<IHeader>(url)
  }

  public getMegaMenu = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country } = args
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      excludePlacements: args?.excludePlacements,
      previewDate: args?.previewDate,
      filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
      profile: 'header',
    })

    const url = `cms/live/page/mega-menu/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`
    logger.info(`get mega menu data from cms ${url}`)
    return this.api.get<IMegaMenu>(url)
  }

  public getFooter = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country, storeId, langId, filterRulesLocaleOverride, previewDate } = args
    const query = generateQuery({
      storeId,
      langId,
      filterRulesLocaleOverride,
      previewDate,
      addPlacement: ['footer_contact', 'footer_other_links'],
    })

    const url = `cms/live/page/footer/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get footer data from cms ${url}`)
    return this.api.get<IFooter>(url)
  }

  public getPageStaticLanding = (args: CmsStaticLandingArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country, offset, storeId, langId, pageId, noEnrich, filterRulesLocaleOverride, previewDate } = args
    const query = generateQuery({
      storeId,
      langId,
      offset,
      pageId,
      noEnrich,
      filterRulesLocaleOverride,
      previewDate,
    })

    const url = `cms/live/page/content/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`
    logger.info(`get page static landing data from cms ${url}`)

    return this.api.get<IPageStaticLanding>(url)
  }

  public getPLPCommerce = (args: IQueryParams) => {
    const { generateQuery, getLang, getCountry, storeName } = this.helper
    const query = generateQuery({
      externalId: args.externalId,
      breadcrumb: args.breadcrumb,
      previewDate: args.previewDate,
      filterRulesLocaleOverride: args.filterRulesLocaleOverride,
      storeId: args?.storeId,
      langId: args?.langId,
      noEnrich: args?.noEnrich,
    })

    const url = `cms/live/plp/content/${storeName}/${getLang(args.locale)}/${getCountry(args.country)}?${query}`

    logger.info(`get plpCommerce data ${url}`)

    return this.api.get<ICommerceHclPage>(url)
  }

  public getSitemapPage = (args: SitemapPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country, hostname } = args
    const query = generateQuery({
      baseUrl: hostname,
    })

    const url = `sitemap/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get sitemap data from cms ${url}`)
    return this.api.get<ISitemap>(url)
  }

  public getPDPCommerce = (args: IQueryParams) => {
    const { generateQuery, getLang, getCountry, storeName } = this.helper
    const query = generateQuery({
      externalId: args.externalId,
      breadcrumb: args.breadcrumb,
      previewDate: args.previewDate,
      filterRulesLocaleOverride: args.filterRulesLocaleOverride,
      storeId: args?.storeId,
      langId: args?.langId,
      noEnrich: args?.noEnrich,
    })

    const url = `cms/live/pdp/content/${storeName}/${getLang(args.locale)}/${getCountry(args.country)}?${query}`

    logger.info(`get pdpCommerce data ${url}`)

    return this.api.get<ICommerceHclPage>(url)
  }
}
export const cmsApiService = new CmsApiService(apiService, helper)
